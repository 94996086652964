$(document).foundation();

$(document).ready( function () {


	reform();
	initHeader();
	initPopup();
	initOfferMenuClose();

// $('form select').selectric();

//header nav font color change on scroll


function initHeader() {
  headerFont();
  $(window).on('scroll', headerFont);
}


function headerFont() {

	if ( window.pageYOffset > $(window).innerHeight() / 2 ) {
		$('.header').addClass('header--scrolled');
	} else {
		$('.header').removeClass('header--scrolled');
	}

	if ( window.pageYOffset > $(window).innerHeight() / 2 ) {
		$('.header').addClass('header--scrolled');
	} else {
		$('.header').removeClass('header--scrolled');
	}
}


//book direct drop down
$( ".direct" ).click(function() {
  $( ".direct-drop" ).fadeIn( "slow", function() {
    // Animation complete.
  });
});

$( ".direct-drop .close" ).click(function() {
  $( ".direct-drop" ).fadeOut( "slow", function() {
    // Animation complete.
  });
});


	// Gets around the touch problems
	$('.off-canvas-primary a').on('click', function () {
		if ( $('html').hasClass('whatinput-types-touch') ) {
			if ( $(this).parent().parent().parent().is('.off-canvas-primary') ) { // Only apply to top level page links

				if ( $(this).parent().hasClass('show-sub') ) {
					// Item already open so allow user to click through
					return true;
				} else {
					// Item is not open so hide any other open ones and show this one.
					// Return false to stop the user clicking through.
					$('.off-canvas-primary .show-sub').removeClass('show-sub');
					$(this).parent().addClass('show-sub');
					return false;
				}
			}
		}
	});


	// Show the sub level nav if within a current section
	$('.current .off-canvas-primary-sub').parent().addClass('show-sub');

	$('.off-canvas-primary a').on('mouseenter', function () {
		if ( $('html').hasClass('whatinput-types-mouse') ) {
			$('.off-canvas-primary .show-sub').removeClass('show-sub');
		}
	});


	//setHomeSliderHeight();
	//$(window).on('resize', setHomeSliderHeight);
	$('.home-slider-inner').reformSlider({
		speed: 6000,
		animationSpeed: 1000
	});

	$('.restaurant-slider__inner').slick({
		autoplay: true,
		fade: true,
		autoplaySpeed: 6500,
		speed: 1000,
		arrows: false,
		dots: false,
		pauseOnHover: false
	});

	if ( $('.home-slider-inner').length > 0 ) {
		loadHomepageImages();
		$(window).on('resize', loadHomepageImages);
	}


	function loadHomepageImages() {

		var viewportOrientation = 'landscape';
		if (window.innerHeight > window.innerWidth) {
			viewportOrientation = 'portrait';
		}

		$('.home-slide').each( function () {
			if ( viewportOrientation == 'portrait' ) {
				$(this).find('.home-slide-image.portrait').css('background-image', 'url("' + $(this).find('.home-slide-image.portrait').data('background-image') + '")');
			} else {
				$(this).find('.home-slide-image').not('.portrait').css('background-image', 'url("' + $(this).find('.home-slide-image').not('.portrait').data('background-image') + '")');
			}
		});

	}









	if ( $('.home-slider-inner').length > 0 ) {
		$(window).on('scroll', checkHomepageScroll);
		checkHomepageScroll();
	}

	if ( $('.restaurant-slider').length > 0 ) {
		$(window).on('scroll', checkRestaurantPageScroll);
		checkRestaurantPageScroll();
	}

	function checkHomepageScroll() {
		if ( window.scrollY >  ( $('.home-slider-inner').outerHeight() - 74 ) || window.scrollY >  ( $('.home-slider-inner').outerHeight() - 74 ) ) {
			$('.header').removeClass('home-header');
		} else {
			$('.header').addClass('home-header');
		}
	}

	function checkRestaurantPageScroll() {
		if ( window.scrollY >  ( $('.restaurant-slider').outerHeight() - 74 ) || window.scrollY >  ( $('.home-slider-inner').outerHeight() - 74 ) ) {
			$('.header').removeClass('home-header');
		} else {
			$('.header').addClass('home-header');
		}
	}


	/*if ( $('.home-slide--video, .landing-video').length > 0 ) {
		scaleVideos();
		$(window).on('resize', scaleVideos);
	}

	function scaleVideos() {
		$('.home-slide--video iframe, .home-slide--video video, .landing-video iframe, .landing-video video').each(function () {
			coverViewport(this);
		});
	}*/


	$('.home-slider-scroll button').on('click', function () {
		$([document.documentElement, document.body]).animate({
	        scrollTop: $('.home-intro').offset().top - 100
	    }, 1000);
	});

	$(".home-slide-caption-inner img").delay(1000).animate({ opacity: 1 }, 700);​	// Gets around the touch problems

	$('.home-slide--video video').fadeIn();


	$('.home-carousel-inner').slick({
		slidesToShow: 1,
		arrows: true,
		autoplaySpeed: 2000,
		autoplay: true,
		responsive: [
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});


	alignArrows();
	$(window).on('resize', alignArrows);



	/*$('.landing-intro-header').bigtext({
		maxfontsize: 180
	});*/


	if ( $('.landing-intro').length > 0 ) {
		alignLandingPage();
		$(window).on('resize', alignLandingPage);
	}

	$('.landing-scroller-button').on('click', function () {
		$('html, body').animate({
	        scrollTop: $(this).parentsUntil('row').next().offset().top - $('.header').outerHeight()
	    }, 700);

	});

	$('.landing-scroller-button__tabs').on('click', function () {
		$('html, body').animate({
	        scrollTop: $(this).parentsUntil('row').next().offset().top - $('.header').outerHeight() + 150
	    }, 700);

	});

	$('.landing-section-image-toggle').on('click', function () {
		$(this).parent().toggleClass('expanded-image');
		$(this).toggleClass('top');
	});

	$('.landing-scroller-wrapper').last().remove();


	// Apply alternating classes to the landing sections
	var count = 1;
	$('.landing-section').each( function () {
		if ( count < 0 ) {
			$(this).addClass('image-on-left');
		} else {
			$(this).addClass('image-on-right');
		}
		count = count * -1;
	});

	// Apply opposite alternating classes for Room section
	var roomCount = 1;
	$('#panel1 .landing-section').each( function () {
		if ( roomCount < 0 ) {
			$(this).removeClass('image-on-right');
			$(this).addClass('image-on-left');
		} else {
			$(this).removeClass('image-on-left');
			$(this).addClass('image-on-right');
		}
		roomCount = roomCount * -1;
	});







	$('.rooms-slider-inner').reformSlider({
		arrows: true,
		speed: 5000,
		animationSpeed: 500
	});

	$('.rooms-content').perfectScrollbar();





	$('.detail-slider').slick({
		arrows: true,
		fade: true,
		autoplay: true,
		autoplaySpeed: 4000
	});


	$('.standard-slider-inner').slick({
		arrows: false,
		fade: true,
		autoplay: true,
		autoplaySpeed: 4000
	});



	if ( $('.history-slider').length > 0 ) {
		initHistorySlider();
	}




	$('.standard-accordion').accordiom({
		showFirstItem: false
	});




	$('.standard-form form').validate();

	$('[name=weddingdate]').fdatepicker({
		format: 'dd/mm/yyyy',
		onRender: function (date) {
			return date.valueOf() < new Date() ? 'disabled' : '';
		}
	});






	$('.gallery-slider').not('.gallery-main--video .gallery-slider').reformSlider({
		arrows: true,
		speed: 4000,
		animationSpeed: 500
	});

	$('.gallery-main--video .gallery-slider').slick({
		fade: true,
		arrows: true
	});

	// pause videos when the slider is changed
	$('.gallery-main--video .gallery-slider').on('afterChange', function (event, slick, currentSlide) {
		$('.gallery-main--video iframe').not('.slick-current iframe').each(function () {
			$(this).attr('src', $(this).attr('src'));
		});
	});


	function initPopup() {
		if ($('#dining-pop-up').length === 0) {
			return
		}

		var popup = $('#dining-pop-up')
		var popupDelay = $('#dining-pop-up').data('popup-delay') || 0;
		var popupKeepHiddenFor = $('#dining-pop-up').data('popup-keep-hidden-for-n-days') || 0;

	

		// Only show the popup if the cookie has not been seen, or always show it for testing in either local or remote markup
		if ( 
			(!Cookies.get('hasSeenPopup')) ||
			(location.hostname.indexOf('localhost') > -1) || 
			(location.hostname.indexOf('projects') > -1) 
		) {
			setTimeout(function() {
				popup.addClass('show')


				if (popupKeepHiddenFor > 0) {
					Cookies.set('hasSeenPopup', 'true', { expires: popupKeepHiddenFor });
				}
			}, popupDelay);

			$('.dining-pop-up__close').on('click', function () {
				popup.removeClass('show')
				setTimeout(function () {
					popup.remove()
				}, 1000)
			})
		} else {
			popup.remove()
		}
	}




	// Vimeo video popups
	$('a[href*="vimeo.com"]').on('click', function () {
		var modal = '<div class="video-modal reveal full" id="video-modal" data-reveal><button class="close-button" data-close aria-label="Close modal" type="button">Close</button><div class="video-modal-container"><iframe src="{{iframeSrc}}" width="640" height="360" frameborder="0" allowfullscreen></iframe></div></div>';

		var $modal = $( modal.replace('{{iframeSrc}}', $(this).attr('href')) );

		$('body').append($modal);

		$('#video-modal').foundation();
        $modal.foundation('open');

		$modal.on('closed.zf.reveal', function () {
	        $modal.remove();
        });

		return false;
	});



	// Show roadwork poppup on location and contact
	if( $('.open-road-works').length > 0 ) {
		setTimeout(function() {
			$('.open-road-works').click();
		},2500);
	}




});


//Careers page recaptcha input check
var recaptchachecked = false;
function recaptchaCallback() {
	$('#message-error-recap').attr('style', 'display: none');
	recaptchachecked = true;
}
function isreCaptchaChecked() {
	//console.log(recaptchachecked);
	if (recaptchachecked == true) {
		$('#message-error-recap').attr('style', 'display: none;');
		$('form input[type=submit]').val('please wait..');
		$('form input[type=submit]').attr('disabled','disabled');
		return recaptchachecked;
	}
	else {
		$('#message-error-recap').attr('style', 'display: block;');
		return recaptchachecked;
	}
}



/*function setHomeSliderHeight() {
	$('.home-slider, .home-slider-inner').height( window.innerHeight );
}*/



function alignArrows() {
	setTimeout(function () {

		// Carousel arrows
		//$('.home-carousel-inner .slick-arrow').css('top', ($('.home-carousel-inner img').first().outerHeight() / 2 - 22) + 'px');

	}, 500);
}



// Full screen alignment of landing page sections and it's content
function alignLandingPage() {

		// Set the top padding of the landing intro area
		var paddingTop = ($(window).outerHeight() - $('.landing-intro-inner').outerHeight()) / 2 + (parseInt($('.landing-intro-inner').css('padding-bottom'), 10) / 1.5);
		if (paddingTop < $('.header').outerHeight()) {
			paddingTop = $('.header').outerHeight();
		}
		$('.landing-intro-inner').css('padding-top',  paddingTop + 'px');

		// If the landing intro is taller than the browser height then let it scroll
		if ( $('.landing-intro-inner').outerHeight() >= $(window).innerHeight() ) {
			$('.landing-intro').addClass('auto-height');
		} else {
			$('.landing-intro').removeClass('auto-height');
		}



		/* Set the height of each landing page section to the window height minus the header
		$('.landing-section').css('height', $(window).innerHeight() - $('.header').outerHeight() ); // Window height minus the header

		// TODO: Check that none of them are taller than the window
		$('.landing-section').each( function () {
			if ( $(this).find('.landing-section-content').height() > $(this).find('.landing-section-image').outerHeight() ) {
				//console.log(this);
			}
		}); */



}



function initHistorySlider() {

	setHistorySlideHeight();
	$(window).on('resize', setHistorySlideHeight);

	$('.history-slide-header-big').bigtext();

	$('.history-slider').slick({
		arrows: true,
		vertical: true,
		verticalSwiping: true,
		infinite: false,
		prevArrow: '<button type="button" class="slick-prev">Previous</button>',
		nextArrow: '<button type="button" class="slick-next">Next</button>'
	});

	$('.history-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
		if ( nextSlide > currentSlide ) {
			$('.history-slider').removeClass('history-animate-forward history-animate-backward');
			$('.history-slider').addClass('history-animate-forward');
		} else {
			$('.history-slider').removeClass('history-animate-forward history-animate-backward');
			$('.history-slider').addClass('history-animate-backward');
		}
	});

	$('.history-slide-content-inner').perfectScrollbar();

}

function setHistorySlideHeight() {
	$('.history-slide').css('height', $(window).innerHeight() - 80 + 'px');
}

function initOfferMenuClose() {
	$('.off-canvas-primary a[href*="#"').on('click', function () {
		$('#offCanvas').foundation('close');
		
		$('#offer-filters button[data-category="' + $(this).attr('href').split('#')[1] + '"]').trigger('click');
	});
}





function coverViewport(element) {
	var viewportWidth = $(window).innerWidth();
	var viewportHeight = $(window).innerHeight();

	var $element = $(element);

	var elementWidth = $element.attr('width');
	var elementHeight = $element.attr('height');

	// Aspect ratio = height / width
	var viewportRatio = viewportHeight / viewportWidth;
	var videoRatio = elementHeight / elementWidth;

	//console.log('Viewport: ', viewportRatio, ' Video: ', videoRatio);


	var newWidth = (elementWidth / elementHeight) * viewportHeight
	var newHeight = (elementHeight / elementWidth) * viewportWidth;
	var newTop = 0;

	if (viewportRatio > videoRatio) {
		// set video height to the viewport, and extend the video beyond the viewport width, i.e. portrait viewport
		//console.log('crop left and right');
		$element.css('height', viewportHeight);
		$element.css('width', newWidth);
		$element.css('left', '-' + (newWidth - viewportWidth) / 2 + 'px');
		$element.css('top', '0');
	} else if (viewportRatio < videoRatio) {
		// set video width to the viewport, and extend the video beyond the viewport height, i.e. landscape viewport
		//console.log('crop top and bottom');
		$element.css('width', viewportWidth);
		$element.css('height', newHeight);
		$element.css('left', '0');
		$element.css('top', '-' + (newHeight - viewportHeight) / 2 + 'px');
	} else {
		// set the video width AND height to the viewport size, i.e. viewport is the same ratio as the video
		//console.log('no crop');
		$element.css('height', viewportHeight);
		$element.css('width', viewportWidth);
		$element.css('left', '0');
		$element.css('top', '0');
	}
}


function initOffersCarousel() {
	var slidesToShow = 3;

	if ( $('.offers-carousel__inner').length > 0 ) {
		alignOffersCarouselArrows();
		$(window).on('resize', alignOffersCarouselArrows);
		$('.offers-carousel__inner').on('setPosition', alignOffersCarouselArrows);
	}

	$('.offers-carousel__inner').each(function () {
		var numberOfRooms = $(this).find('.offers-carousel__item').length;

		if ( numberOfRooms > 3 ) {
			$(this).addClass('center-mode');
		} else if (numberOfRooms == 1) {
			$(this).addClass('one-item');
			slidesToShow = 1;
		} else if (numberOfRooms == 2 || numberOfRooms == 3) {
			$(this).addClass('two-items');
			slidesToShow = 2;
		}
	});

	$('.offers-carousel__inner').slick({
		slidesToShow: slidesToShow,
		centerMode: true,
		centerPadding: 0,
		arrows: true,
		dots: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});
}

function alignOffersCarouselArrows() {
	$('.offers-carousel__inner').each(function () {
		var imgHeight = $(this).find('.slick-current .offers-carousel__image').outerHeight() / 2;

		$(this).find('.slick-arrow').css('top', imgHeight);
	});
}

function initLastItem() {
	$( ".offers-landing__item, .landing__dining-item" ).last().addClass("end");
}
